
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import "./sub_plan_screen.css";
import SubPlanComponent from "./components/sub_plan_component/sub_plan_component";
import { Button } from "@mui/material";
import Popup from "reactjs-popup";
import { MdClose } from "react-icons/md";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import axios from "../../../../../network/api";
import { baseUrl } from "../../../../../network/constants/constants";
import config from "../../../../../network/auth";
import AppConstants from "../../../../AppConstants";


const SubPlanScreen = ({ onPageChange, planId }) => {
  const [subPlans, setSubPlans] = useState([]);
  const [newSubPlan, setNewSubPlan] = useState({
    sub_plan_title: "",
    price: "",
    validity: "",
    visits: "",
    plan_info: [],
  });
  const [newPlanInfo, setNewPlanInfo] = useState([{ info: "" }]);
  const [loading, setLoading] = useState(false);

  const bID = localStorage.getItem(AppConstants.business_id);

  useEffect(() => {
    fetchSubPlans();
  }, [planId]);

  const handlePlanClick = () => {
    onPageChange("plans");
  };

  const handleAddChange = (event) => {
    const { name, value } = event.target;
    setNewSubPlan({
      ...newSubPlan,
      [name]: value,
    });
  };

  const handleInfoChange = (index, event) => {
    const values = [...newPlanInfo];
    values[index].info = event.target.value;
    setNewPlanInfo(values);
  };

  const handleAddPlanInfoChange = () => {
    setNewPlanInfo([...newPlanInfo, { info: "" }]);
  };

  const handleRemovePlanInfo = (index) => {
    const values = [...newPlanInfo];
    values.splice(index, 1);
    setNewPlanInfo(values);
  };

  const handleSubmit = (event, close) => {
    event.preventDefault();
    handleAddSubplan(close);
  };


  // delete subplan
  const deleteSubPlan = (subPlanId) => {
    setLoading(true);
  
    axios
      .delete(`${baseUrl}app/subplan/delete/${subPlanId}/`, config)
      .then((response) => {
        setSubPlans((prevSubPlans) => prevSubPlans.filter((subplan) => subplan.id !== subPlanId));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Subplans Error:", error);
        setLoading(false);
      });
  };
  

// patch subplan

const editSubPlan = (subPlanId, editedSubPlan, close) => {
  setLoading(true);

  const payload = {
    
    sub_plan_title: editedSubPlan.sub_plan_title,
    price: editedSubPlan.price,
    validity: editedSubPlan.validity,
    visits: editedSubPlan.visits,
    plan_info: editedSubPlan.plan_info,
    plan_id:planId,
    b_id: bID
  };

  axios
    .patch(`${baseUrl}app/subplan/edit/${subPlanId}/`, payload, config)
    .then((response) => {
      fetchSubPlans();
      response.data.forEach(editedsubPlan => {
      console.log(editedsubPlan);
      });
      console.log("<<<<"+response.data);
      setLoading(false);
      close();
    })
    .catch((error) => {
      console.error("Subplans Error:", error);
      setLoading(false);
    });
};

  
  // post subplan

  const handleAddSubplan = (close) => {
    setLoading(true);

    const payload = {
      sub_plan_title: newSubPlan.sub_plan_title,
      price: newSubPlan.price,
      validity: newSubPlan.validity,
      visits: newSubPlan.visits,
      plan_info: newPlanInfo.map((item) => item.info).join(", "),
      plan_id: planId,
      b_id: bID,
    };

    axios
      .post(`${baseUrl}app/subplan/create/`, payload, config)
      .then((response) => {
        fetchSubPlans();
        setNewSubPlan({
          sub_plan_title: "",
          price: "",
          validity: "",
          visits: "",
          plan_info: [],
        });
        setNewPlanInfo([{ info: "" }]);
        setLoading(false);
        close();
      })
      .catch((error) => {
        console.error("Subplans Error:", error);
        setLoading(false);
      });
  };


  // get subplan
  const fetchSubPlans = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/subplan-by-plan/${planId}/`, config)
      .then((response) => {
        setSubPlans(response.data);
        // response.data.forEach(subPlan => {
        //   console.log(subPlan);
        // });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Subplans Error:", error);
        setLoading(false);
      });
  };

  return (
    <div>
      <h1>My SubPlan</h1>
      <Button className="go-back" onClick={handlePlanClick}>
        Go Back
      </Button>
      <Container fluid>
        <div className="sub-plan-container">
          {subPlans.length > 0 ? (
            subPlans.map((subplan) => (
              <SubPlanComponent
              key={subplan.id}
              id={subplan.id}
              sub_plan_title={subplan.sub_plan_title}
              price={subplan.price}
              validity={subplan.validity}
              visits={subplan.visits}
              plan_info={subplan.plan_info}
              thumb={subplan.thumb}
              onDelete={deleteSubPlan}
              onEditPlan={editSubPlan}  
              planId={planId}
              />
            ))
          ) : (
            <div>
              <p className="clean">No Sub plans</p>
            </div>
          )}
        </div>
        <Popup
          className="add-popup-container"
          trigger={<button className="btn-add">+ Add sub plans</button>}
          modal
          nested
        >
          {(close) => (
            <div>
              <div className="close-btn" onClick={close}>
                <MdClose />
              </div>
              <div className="add-plan-div">
                <form onSubmit={(e) => handleSubmit(e, close)}>
                  <div className="heading">Add Sub Plan</div>
                  <Box>
                    <TextField
                      className="plan-form-textfield"
                      name="sub_plan_title"
                      label="Subplan Name"
                      variant="outlined"
                      size="small"
                      value={newSubPlan.sub_plan_title}
                      onChange={handleAddChange}
                    />
                    <TextField
                      className="plan-form-textfield"
                      name="price"
                      label="Price"
                      variant="outlined"
                      size="small"
                      value={newSubPlan.price}
                      onChange={handleAddChange}
                      type='number'
                    />
                    <TextField
                      className="plan-form-textfield"
                      name="validity"
                      label="Validity"
                      variant="outlined"
                      size="small"
                      value={newSubPlan.validity}
                      onChange={handleAddChange}
                      type='number'
                    />
                    <TextField
                      className="plan-form-textfield"
                      name="visits"
                      label="Number of Visits"
                      variant="outlined"
                      size="small"
                      value={newSubPlan.visits}
                      onChange={handleAddChange}
                      type='number'
                    />
                    <div className="plan-info">Plan Info</div>
                    {newPlanInfo.map((plan, index) => (
                      <div className="wrapper" key={index}>
                        <TextField
                          name="info"
                          label="Plan Info"
                          variant="outlined"
                          className="planinfo-form-textfield"
                          size="small"
                          value={plan.info}
                          onChange={(e) => handleInfoChange(index, e)}
                        />
                        <span
                          onClick={() => handleRemovePlanInfo(index)}
                          className="material-icons remove-icon"
                        >
                          delete_forever
                        </span>
                      </div>
                    ))}
                    <div className="plan-info-btn-container">
                      <Button
                        className="add-plan-info"
                        onClick={handleAddPlanInfoChange}
                      >
                        + Plan Info
                      </Button>
                    </div>
                    <Button className="plan-submit-btn" type="submit">
                      Submit
                    </Button>
                  </Box>
                </form>
              </div>
            </div>
          )}
        </Popup>
      </Container>
    </div>
  );
};

export default SubPlanScreen;







































// import React, { useState, useEffect } from "react";
// import Container from "react-bootstrap/Container";
// import "./sub_plan_screen.css";
// import SubPlanComponent from "./components/sub_plan_component/sub_plan_component";
// import { Button } from "@mui/material";
// import Popup from "reactjs-popup";
// import { MdClose } from "react-icons/md";
// import TextField from "@mui/material/TextField";
// import Box from "@mui/material/Box";
// import axios from "../../../../../network/api";
// import { baseUrl } from "../../../../../network/constants/constants";
// import config from "../../../../../network/auth";
// import AppConstants from "../../../../AppConstants";


// const SubPlanScreen = ({ onPageChange, planId }) => {
//   const [subPlans, setSubPlans] = useState([]);
//   const [newSubPlan, setNewSubPlan] = useState({
//     sub_plan_title: "",
//     price: "",
//     validity: "",
//     visits: "",
//     plan_info: [],
//   });
//   const [newPlanInfo, setNewPlanInfo] = useState([{ info: "" }]);
//   const [isOpenForm, setIsOpenForm] = useState(false);
//   const [loading, setLoading] = useState(false);

//   const bID = localStorage.getItem(AppConstants.business_id);

//   useEffect(() => {
//     fetchSubPlans();
//   }, [planId]);

//   const handlePlanClick = () => {
//     onPageChange("plans");
//   };

//   const handleAddChange = (event) => {
//     const { name, value } = event.target;
//     setNewSubPlan({
//       ...newSubPlan,
//       [name]: value,
//     });
//   };

//   const handleInfoChange = (index, event) => {
//     const values = [...newPlanInfo];
//     values[index].info = event.target.value;
//     setNewPlanInfo(values);
//   };

//   const handleAddPlanInfoChange = () => {
//     setNewPlanInfo([...newPlanInfo, { info: "" }]);
//   };

//   const handleRemovePlanInfo = (index) => {
//     const values = [...newPlanInfo];
//     values.splice(index, 1);
//     setNewPlanInfo(values);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     handleAddSubplan();
//   };


//   // delete subplan
//   const deleteSubPlan = (subPlanId) => {
//     setLoading(true);
  
//     axios
//       .delete(`${baseUrl}app/subplan/delete/${subPlanId}/`, config)
//       .then((response) => {
//         setSubPlans((prevSubPlans) => prevSubPlans.filter((subplan) => subplan.id !== subPlanId));
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Subplans Error:", error);
//         setLoading(false);
//       });
//   };
  

// // patch subplan

// const editSubPlan = (subPlanId, newDetails, close) => {
//   setLoading(true);

//   const payload = {
//     sub_plan_title: newDetails.sub_plan_title,
//     price: newDetails.price,
//     validity: newDetails.validity,
//     visits: newDetails.visits,
//     plan_info: newDetails.plan_info,
//     plan_id: newDetails.planId,
//     b_id: bID,
//     sub_plan_id: newDetails.subPlanId,
//   };

//   axios
//     .patch(`${baseUrl}app/subplan/edit/${subPlanId}`, payload, config)
//     .then((response) => {
//       fetchSubPlans();
//       setLoading(false);
//       close();
//     })
//     .catch((error) => {
//       console.error("Subplans Error:", error);
//       setLoading(false);
//     });
// };

  
//   // post subplan

//   const handleAddSubplan = (close) => {
//     setLoading(true);

//     const payload = {
//       sub_plan_title: newSubPlan.sub_plan_title,
//       price: newSubPlan.price,
//       validity: newSubPlan.validity,
//       visits: newSubPlan.visits,
//       plan_info: newPlanInfo.map((item) => item.info),
//       plan_id: planId,
//       b_id: bID,
//     };

//     axios
//       .post(`${baseUrl}app/subplan/create/`, payload, config)
//       .then((response) => {
//         fetchSubPlans();
//         setNewSubPlan({
//           sub_plan_title: "",
//           price: "",
//           validity: "",
//           visits: "",
//           plan_info: [],
//         });
//         setNewPlanInfo([{ info: "" }]);
//         setLoading(false);
//         close();
//       })
//       .catch((error) => {
//         console.error("Subplans Error:", error);
//         setLoading(false);
//       });
//   };


//   // get subplan
//   const fetchSubPlans = () => {
//     setLoading(true);
//     axios
//       .get(`${baseUrl}app/subplan-by-plan/${planId}/`, config)
//       .then((response) => {
//         setSubPlans(response.data);
//         // response.data.forEach(subPlan => {
//         //   console.log(subPlan);
//         // });
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Subplans Error:", error);
//         setLoading(false);
//       });
//   };

//   return (
//     <div>
//       <h1>My SubPlan</h1>
//       <Button className="go-back" onClick={handlePlanClick}>
//         Go Back
//       </Button>
//       <Container fluid>
//         <div className="sub-plan-container">
//           {subPlans.length > 0 ? (
//             subPlans.map((subplan) => (
//               <SubPlanComponent
//                 key={subplan.id}
//                 id={subplan.id}
//                 sub_plan_title={subplan.sub_plan_title}
//                 price={subplan.price}
//                 validity={subplan.validity}
//                 visits={subplan.visits}
//                 plan_info={subplan.plan_info}
//                 thumb={subplan.thumb}
//                 onDelete={deleteSubPlan}
//                 onEditPlan={(newDetails) => editSubPlan(subplan.id, newDetails)}
//                 planId={planId}
//               />
//             ))
//           ) : (
//             <div>
//               <p className="clean">No Sub plans</p>
//             </div>
//           )}
//         </div>
//         <Popup
//           className="add-popup-container"
//           trigger={<button className="btn-add">+ Add sub plans</button>}
//           modal
//           nested
//         >
//           {(close) => (
//             <div>
//               <div className="close-btn" onClick={close}>
//                 <MdClose />
//               </div>
//               <div className="add-plan-div">
//                 <form onSubmit={(e) => handleSubmit(e, close)}>
//                   <div className="heading">Add Sub Plan</div>
//                   <Box>
//                     <img src="" alt="" />
//                     <TextField
//                       className="plan-form-textfield"
//                       name="sub_plan_title"
//                       label="Subplan Name"
//                       variant="outlined"
//                       size="small"
//                       value={newSubPlan.sub_plan_title}
//                       onChange={handleAddChange}
//                     />
//                     <TextField
//                       className="plan-form-textfield"
//                       name="price"
//                       label="Price"
//                       variant="outlined"
//                       size="small"
//                       value={newSubPlan.price}
//                       onChange={handleAddChange}
//                     />
//                     <TextField
//                       className="plan-form-textfield"
//                       name="validity"
//                       label="Validity"
//                       variant="outlined"
//                       size="small"
//                       value={newSubPlan.validity}
//                       onChange={handleAddChange}
//                     />
//                     <TextField
//                       className="plan-form-textfield"
//                       name="visits"
//                       label="Number of Visits"
//                       variant="outlined"
//                       size="small"
//                       value={newSubPlan.visits}
//                       onChange={handleAddChange}
//                     />
//                     <div className="plan-info">Plan Info</div>
//                     {newPlanInfo.map((plan, index) => (
//                       <div className="wrapper" key={index}>
//                         <TextField
//                           name="info"
//                           label="Plan Info"
//                           variant="outlined"
//                           className="planinfo-form-textfield"
//                           size="small"
//                           value={plan.info}
//                           onChange={(e) => handleInfoChange(index, e)}
//                         />
//                         <span
//                           onClick={() => handleRemovePlanInfo(index)}
//                           className="material-icons remove-icon"
//                         >
//                           delete_forever
//                         </span>
//                       </div>
//                     ))}
//                     <div className="plan-info-btn-container">
//                       <Button
//                         className="add-plan-info"
//                         onClick={handleAddPlanInfoChange}
//                       >
//                         + Plan Info
//                       </Button>
//                     </div>
//                     <Button className="plan-submit-btn" type="submit">
//                       Submit
//                     </Button>
//                   </Box>
//                 </form>
//               </div>
//             </div>
//           )}
//         </Popup>
//       </Container>
//     </div>
//   );
// };

// export default SubPlanScreen;









