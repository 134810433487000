


import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MdClose } from "react-icons/md";
import Popup from "reactjs-popup";
import "./sub_plan_component.css";

const SubPlanComponent = ({
  id,
  sub_plan_title,
  price,
  validity,
  visits,
  plan_info,
  onDelete,
  onEditPlan,
  planId
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editingSubPlan, setEditingSubPlan] = useState(false);
  const [editedSubPlan, setEditedSubPlan] = useState({
    sub_plan_title,
    price,
    validity,
    visits,
    plan_info,
  });
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editPopupOpen, setEditPopupOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Delete subplan
  const handleDelete = () => {
    setDeleteConfirmationOpen(true);
    handleClose();
  };

  const handleConfirmDelete = () => {
    onDelete(id);
    setDeleteConfirmationOpen(false);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationOpen(false);
  };

  // Edit subplan
  const handleEditSubPlan = () => {
    setEditingSubPlan(true);
    setEditPopupOpen(true);
    handleClose();
  };

  const handleSubPlanChange = (event) => {
    setEditedSubPlan({
      ...editedSubPlan,
      [event.target.name]: event.target.value,
  
    });
  };

  const handleSubmitEdit = () => {
    const newDetails = {
      ...editedSubPlan
    };
    onEditPlan(id,editedSubPlan);
    setEditingSubPlan(false);
    setEditPopupOpen(false);
    console.log(newDetails);
    // Object.entries(newDetails).forEach(([key, value]) => {
    //   console.log(`${key}: ${value}`);
    // });
  };

  const handleCancelEdit = () => {
    setEditingSubPlan(false);
    setEditPopupOpen(false);
  };

  return (
    <Card className="sub-plan-card">
      <Card.Body>
        <IconButton
          className="menu-icon-subplan"
          aria-label="more"
          id="long-button"
          aria-controls={anchorEl ? "long-menu" : undefined}
          aria-expanded={anchorEl ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleMenuOpen}
        >
          <span className="material-icons menu-icon">more_vert</span>
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEditSubPlan}>Edit</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
        <Card.Title className="card-title">{sub_plan_title}</Card.Title>
        <div className="card-items">
          <Card.Subtitle>Price: {price}</Card.Subtitle>
          <Card.Subtitle>Validity: {validity}</Card.Subtitle>
          <Card.Subtitle>Visits: {visits}</Card.Subtitle>
          <Card.Subtitle>Plan Info: {plan_info}</Card.Subtitle>
        </div>
        <Popup
          contentStyle={{ backgroundColor: "white" }}
          open={editPopupOpen}
          onClose={handleCancelEdit}
          modal
          nested
        >
          {(close) => (
            <div>
              <div className="close-btn" onClick={close}>
                <MdClose />
              </div>
              <div className="edit-div-subplan">
                <h3 className="edit-heading">Edit Sub Plan</h3>
                <TextField
                  className="plan-form-textfield"
                  name="sub_plan_title"
                  label="Name"
                  variant="outlined"
                  size="small"
                  value={editedSubPlan.sub_plan_title}
                  onChange={handleSubPlanChange}
                />
                <TextField
                  className="plan-form-textfield"
                  name="price"
                  label="Price"
                  variant="outlined"
                  size="small"
                  value={editedSubPlan.price}
                  onChange={handleSubPlanChange}
                />
                <TextField
                  className="plan-form-textfield"
                  name="validity"
                  label="Validity"
                  variant="outlined"
                  size="small"
                  value={editedSubPlan.validity}
                  onChange={handleSubPlanChange}
                />
                <TextField
                  className="plan-form-textfield"
                  name="visits"
                  label="Number of Visits"
                  variant="outlined"
                  size="small"
                  value={editedSubPlan.visits}
                  onChange={handleSubPlanChange}
                />
                <TextField
                  className="plan-form-textfield"
                  name="plan_info"
                  label="Plan Info"
                  variant="outlined"
                  size="small"
                  value={editedSubPlan.plan_info}
                  onChange={handleSubPlanChange}
                />
                <div className="edit-btn-div">
                  <Button onClick={handleSubmitEdit}>Save</Button>
                  <Button onClick={handleCancelEdit} className="cancel-btn">Cancel</Button>
                </div>
              </div>
            </div>
          )}
        </Popup>
        <Popup
          contentStyle={{ borderRadius: "20px", backgroundColor: "white" }}
          open={deleteConfirmationOpen}
          onClose={handleCancelDelete}
          modal
          nested
        >
          {(close) => (
            <div className="delete-div">
              <h3 className="delete-heading">Are you sure?</h3>
              <p className="delete-p">Do you want to delete this subplan?</p>
              <div className="delete-btn-div">
                <Button onClick={handleConfirmDelete}>Confirm</Button>
                <Button
                  onClick={() => {
                    handleCancelDelete();
                    close();
                  }}
                  className="cancel-btn"
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </Popup>
      </Card.Body>
    </Card>
  );
};

export default SubPlanComponent;

























































// import React, { useState } from "react";
// import Card from "react-bootstrap/Card";
// import IconButton from "@mui/material/IconButton";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import { MdClose } from "react-icons/md";
// import Popup from "reactjs-popup";
// import "./sub_plan_component.css";

// const SubPlanComponent = ({
//   id,
//   sub_plan_title,
//   price,
//   validity,
//   visits,
//   plan_info,
//   onDelete,
//   onEditPlan,
//   planId
// }) => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [editingSubPlan, setEditingSubPlan] = useState(false);
//   const [editedSubPlan, setEditedSubPlan] = useState({
//     sub_plan_title,
//     price,
//     validity,
//     visits,
//     plan_info,
//   });
//   const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
//   const [editPopupOpen, setEditPopupOpen] = useState(false);

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   // Delete subplan
//   const handleDelete = () => {
//     setDeleteConfirmationOpen(true);
//     handleClose();
//   };

//   const handleConfirmDelete = () => {
//     onDelete(id);
//     setDeleteConfirmationOpen(false);
//   };

//   const handleCancelDelete = () => {
//     setDeleteConfirmationOpen(false);
//   };

//   // Edit subplan
//   const handleEditSubPlan = () => {
//     setEditingSubPlan(true);
//     setEditPopupOpen(true);
//     handleClose();
//   };

//   const handleSubPlanChange = (event) => {
//     setEditedSubPlan({
//       ...editedSubPlan,
//       [event.target.name]: event.target.value,
//     });
//   };

//   const handleSubmitEdit = () => {
//     const updatedPlanInfo = editedSubPlan.plan_info;
//     const newDetails = {
//       ...editedSubPlan,
//       planInfo: updatedPlanInfo,
//     };
//     onEditPlan(id, newDetails);
//     setEditingSubPlan(false);
//     setEditPopupOpen(false);
//   };

//   const handleCancelEdit = () => {
//     setEditingSubPlan(false);
//     setEditPopupOpen(false);
//   };

//   return (
//     <Card className="plan-card">
//       <Card.Body>
//         <IconButton
//           className="menu-icon"
//           aria-label="more"
//           id="long-button"
//           aria-controls={anchorEl ? "long-menu" : undefined}
//           aria-expanded={anchorEl ? "true" : undefined}
//           aria-haspopup="true"
//           onClick={handleMenuOpen}
//         >
//           <span className="material-icons menu-icon">more_vert</span>
//         </IconButton>
//         <Menu
//           id="long-menu"
//           MenuListProps={{
//             "aria-labelledby": "long-button",
//           }}
//           anchorEl={anchorEl}
//           open={Boolean(anchorEl)}
//           onClose={handleClose}
//         >
//           <MenuItem onClick={handleEditSubPlan}>Edit</MenuItem>
//           <MenuItem onClick={handleDelete}>Delete</MenuItem>
//         </Menu>
//         <Card.Title className="card-title">{sub_plan_title}</Card.Title>
//         <div className="card-items">
//           <Card.Subtitle>Price: {price}</Card.Subtitle>
//           <Card.Subtitle>Validity: {validity}</Card.Subtitle>
//           <Card.Subtitle>Visits: {visits}</Card.Subtitle>
//           <Card.Subtitle>Plan Info: {plan_info}</Card.Subtitle>
//         </div>
//         <Popup
//           contentStyle={{ backgroundColor: "white" }}
//           open={editPopupOpen}
//           onClose={handleCancelEdit}
//           modal
//           nested
//         >
//           {(close) => (
//             <div>
//               <div className="close-btn" onClick={close}>
//                 <MdClose />
//               </div>
//               <div className="edit-div-subplan">
//                 <h3 className="edit-heading">Edit Sub Plan</h3>
//                 <TextField
//                   className="plan-form-textfield"
//                   name="sub_plan_title"
//                   label="Name"
//                   variant="outlined"
//                   size="small"
//                   value={editedSubPlan.sub_plan_title}
//                   onChange={handleSubPlanChange}
//                 />
//                 <TextField
//                   className="plan-form-textfield"
//                   name="price"
//                   label="Price"
//                   variant="outlined"
//                   size="small"
//                   value={editedSubPlan.price}
//                   onChange={handleSubPlanChange}
//                 />
//                 <TextField
//                   className="plan-form-textfield"
//                   name="validity"
//                   label="Validity"
//                   variant="outlined"
//                   size="small"
//                   value={editedSubPlan.validity}
//                   onChange={handleSubPlanChange}
//                 />
//                 <TextField
//                   className="plan-form-textfield"
//                   name="visits"
//                   label="Number of Visits"
//                   variant="outlined"
//                   size="small"
//                   value={editedSubPlan.visits}
//                   onChange={handleSubPlanChange}
//                 />
//                 <TextField
//                   className="plan-form-textfield"
//                   name="plan_info"
//                   label="Plan Info"
//                   variant="outlined"
//                   size="small"
//                   value={editedSubPlan.plan_info}
//                   onChange={handleSubPlanChange}
//                 />
//                 <div className="edit-btn-div">
//                   <Button onClick={handleSubmitEdit}>Save</Button>
//                   <Button onClick={handleCancelEdit} className="cancel-btn">Cancel</Button>
//                 </div>
//               </div>
//             </div>
//           )}
//         </Popup>
//         <Popup
//           contentStyle={{ borderRadius: "20px", backgroundColor: "white" }}
//           open={deleteConfirmationOpen}
//           onClose={handleCancelDelete}
//           modal
//           nested
//         >
//           {(close) => (
//             <div className="delete-div">
//               <h3 className="delete-heading">Are you sure?</h3>
//               <p className="delete-p">Do you want to delete this subplan?</p>
//               <div className="delete-btn-div">
//                 <Button onClick={handleConfirmDelete}>Confirm</Button>
//                 <Button
//                   onClick={() => {
//                     handleCancelDelete();
//                     close();
//                   }}
//                   className="cancel-btn"
//                 >
//                   Cancel
//                 </Button>
//               </div>
//             </div>
//           )}
//         </Popup>
//       </Card.Body>
//     </Card>
//   );
// };

// export default SubPlanComponent;











