import "./home.css";
import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Logo from "./../../assets/logo.svg";
import { menuList } from "./menu/menu_data";
import Menu from "./menu/menu";
import MyAppBar from "./appbar/my_appbar";
import AppConstants from "./../AppConstants";
import Dashboard from "./content/dashboard/dashboard";
import Gallery from "./content/gallery/gallery";
import Settings from "./content/settings/settings";
import Unsubscribe from "./content/unsubscribe/unsubscribe";
import MainPlan from "./content/plans/main_plan";
import Faq from "./content/faq/faq";
import Subscription from "./content/subscription/subscription";

const openedMixin = (theme) => ({
  width: AppConstants.drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: AppConstants.drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Home() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [selectedItemFromMenu, setSelectedItemFromMenu] = useState(0);

  // Function to receive the selected item from the Menu component
  const handleSelectedItemChange = (selectedItem) => {
    setSelectedItemFromMenu(selectedItem);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <MyAppBar
        open={open}
        selectedItemFromMenu={selectedItemFromMenu}
        sendDrawerOpen={handleDrawerOpen}
      />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img src={Logo} className="brand-logo" alt=""/>
          <div className="brand-name ms-2 me-auto">Onescribe Business</div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Menu open={open} sendSelectedItemToParent={handleSelectedItemChange} />
        <Divider />
        <List>
          {/* {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))} */}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {/* {menuList && menuList.length === 0
      ? <Dashboard/>
      : <Gallery/>} */}

        {(() => {
          switch (selectedItemFromMenu) {
            case 0:
              return <Dashboard />;
            case 1:
              return <Subscription />;
            case 2:
              return <MainPlan />;
            case 3:
              return <Unsubscribe />;
            case 4:
              return <Gallery />;
            case 5:
              return <Faq />;
            case 6:
              return <Settings/>;

            default:
              return <Dashboard />;
          }
        })()}

        {/* <Typography paragraph>
         Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
         eiusmod tempor incididunt ut labore et dolore magna aliqua. Rhoncus
         dolor purus non enim praesent elementum facilisis leo vel. Risus at
         ultrices mi tempus imperdiet. Semper risus in hendrerit gravida rutrum
         quisque non tellus. Convallis convallis tellus id interdum velit
         laoreet id donec ultrices. Odio morbi quis commodo odio aenean sed
         adipiscing. Amet nisl suscipit adipiscing bibendum est ultricies
         integer quis. Cursus euismod quis viverra nibh cras. Metus vulputate
         eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo
         quis imperdiet massa tincidunt. Cras tincidunt lobortis feugiat
         vivamus at augue. At augue eget arcu dictum varius duis at consectetur
         lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa sapien
         faucibus et molestie ac.
       </Typography> */}
      </Box>
    </Box>
  );
}
