

import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Popup from "reactjs-popup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "../../../../../network/api";
import { baseUrl } from "../../../../../network/constants/constants";
import "./plan_screen.css";
import PlanComponent from "./components/plan_component/plan_component";
import AppConstants from "../../../../AppConstants";
import config from "../../../../../network/auth";
import "./plan_screen.css";

const PlanScreen = ({ onPageChange }) => {
  const [plans, setPlans] = useState([]);
  const [newPlanName, setNewPlanName] = useState("");
  const [loading, setLoading] = useState(false);

  let bID = localStorage.getItem(AppConstants.business_id);

  const handlePlanClick = (planId) => {
    onPageChange("subplans", planId);
  };

  const handlePlanChange = (event) => {
    setNewPlanName(event.target.value);
  };

 

  // Delete plan
  const deletePlan = (planId) => {
    setLoading(true);

    axios
      .delete(baseUrl + `app/plan/update-delete/${planId}/`, config)
      .then((response) => {
        setPlans(plans.filter((plan) => plan.id !== planId));
        setLoading(false);
      })
      .catch((error) => {
        console.error("plans Error:", error);
        setLoading(false);
      });
  };

   // Patch plan
   const editPlan = (planId, newName) => {
    setLoading(true);

    const payload = {
      plan_title: newName,
    };

    axios
      .patch(baseUrl + `app/plan/update-delete/${planId}/`, payload, config)
      .then((response) => {
        fetchPlans();
        setLoading(false);
      })
      .catch((error) => {
        console.error("plans Error:", error);
        setLoading(false);
      });
  };


  // Post plan
  const handleAddPlan = (close) => {
    setLoading(true);

    const payload = {
      plan_title: newPlanName,
      biz_id: bID,
    };

    axios
      .post(baseUrl + "app/plan/list-create/", payload, config)
      .then((response) => {
        fetchPlans();
        setNewPlanName("");
        setLoading(false);
        close();
      })
      .catch((error) => {
        console.error("plans Error:", error);
        setLoading(false);
      });
  };

  // Get plans
  const fetchPlans = () => {
    setLoading(true);
    axios
      .get(baseUrl + `app/plan-by-bid/${bID}/`, config)
      .then((response) => {
        setPlans(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("plans Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <div>
      My Plan
      <Container fluid>
        <div className="plan-container">
          {plans.length > 0 ? (
            plans.map((plan) => (
              <div key={plan.id}>
                <PlanComponent
                  id={plan.id}
                  name={plan.plan_title}
                  onDelete={deletePlan}
                  onEditPlan={editPlan}
                  onPlanClick={() => handlePlanClick(plan.id)}
                />
              </div>
            ))
          ) : (
            <div>
              <p className="clean">No plans</p>
            </div>
          )}
        </div>

        <Popup
          contentStyle={{ borderRadius: "20px" }}
          trigger={<button className="btn-add">+ Add plans</button>}
          modal
          nested
        >
          {(close) => (
            <div className="add-plan-div">
              <h5 className="add-plan-heading">Add plan name</h5>
              <TextField
                className="add-plan-input"
                label="Plan name"
                type="text"
                name="plan"
                value={newPlanName}
                onChange={handlePlanChange}
              />
              <div className="delete-btn-div">
                <Button type="submit" onClick={() => handleAddPlan(close)}>
                  Submit
                </Button>
                <Button onClick={close} className="cancel-btn">
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </Popup>
      </Container>
    </div>
  );
};

export default PlanScreen;














// import React, { useState, useEffect } from "react";
// import Container from "react-bootstrap/Container";
// import Popup from "reactjs-popup";
// import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";
// import axios from "../../../../../network/api";
// import { baseUrl } from "../../../../../network/constants/constants";
// import "./plan_screen.css";
// import PlanComponent from "./components/plan_component/plan_component";
// import AppConstants from "../../../../AppConstants";
// import config from "../../../../../network/auth";
// import "./plan_screen.css";

// const PlanScreen = ({ onPageChange }) => {
//   const [plans, setPlans] = useState([]);
//   const [newPlanName, setNewPlanName] = useState("");
//   const [loading, setLoading] = useState(false);

//   let bID = localStorage.getItem(AppConstants.business_id);

//   const handlePlanClick = () => {
//     onPageChange();
//   };

//   const handlePlanChange = (event) => {
//     setNewPlanName(event.target.value);
//   };

// //Add plan
//   const handleAddPlan = (close) => {
//     setLoading(true);

//     const payload = {
//       plan_title: newPlanName,
//       biz_id: bID,
//     };

//     axios
//       .post(baseUrl + "app/plan/list-create/", payload, config)
//       .then((response) => {
//         fetchPlans();
//         setNewPlanName("");
//         setLoading(false);
//         close();
//       })
//       .catch((error) => {
//         console.error("plans Error:", error);
//         setLoading(false);
//       });
//   };

//   //Edit plan
//   const editPlan = (planId, newName) => {
//     setLoading(true);

//     const payload = {
//       plan_title: newName,
//     };

//     axios
//       .patch(baseUrl + `app/plan/update-delete/${planId}/`, payload, config)
//       .then((response) => {
//         fetchPlans();
//         // console.log(response.data);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("plans Error:", error);
//         setLoading(false);
//       });
//   };

//   // Delete plan
//   const deletePlan = (planId) => {
//     setLoading(true);

//     axios
//       .delete(baseUrl + `app/plan/update-delete/${planId}/`, config)
//       .then((response) => {
//         setPlans(plans.filter((plan) => plan.id !== planId));
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("plans Error:", error);
//         setLoading(false);
//       });
//   };

//   // fetch plan
//   const fetchPlans = () => {
//     setLoading(true);
//     axios
//       .get(baseUrl + `app/plan-by-bid/${bID}/`, config)
//       .then((response) => {
//         setPlans(response.data);
//         // console.log(response.data)
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("plans Error:", error);
//         setLoading(false);
//       });
//   };

//   useEffect(() => {
//     fetchPlans();
//   }, []);

//   return (
//     <div>
//       My Plan
//       <Container fluid>
//         <div className="plan-container">
//           {plans.length > 0 ? (
//             plans.map((plan) => (
//               <div key={plan.id}>
//                 <PlanComponent
//                   id={plan.id}
//                   name={plan.plan_title}
//                   onDelete={deletePlan}
//                   onEditPlan={editPlan}
//                   onPlanClick={handlePlanClick}
//                 />
//               </div>
//             ))
//           ) : (
//             <div>
//               <p className="clean">No plans</p>
//             </div>
//           )}
//         </div>

//         <Popup
//           contentStyle={{ borderRadius: "20px" }}
//           trigger={<button className="btn-add">+ Add plans</button>}
//           modal
//           nested
//         >
//           {(close) => (
//             <div className="add-plan-div">
//               <h5 className="add-plan-heading">Add plan name</h5>
//               <TextField
//                 className="add-plan-input"
//                 label="Plan name"
//                 type="text"
//                 name="plan"
//                 value={newPlanName}
//                 onChange={handlePlanChange}
//               />
//               <div className="delete-btn-div">
//                 <Button type="submit" onClick={() => handleAddPlan(close)}>
//                   Submit
//                 </Button>
//                 <Button onClick={close} className="cancel-btn">
//                   Cancel
//                 </Button>
//               </div>
//             </div>
//           )}
//         </Popup>
//       </Container>
//     </div>
//   );
// };

// export default PlanScreen;

