import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../../network/api";
import { baseUrl } from "../../../../network/constants/constants";
import config from "../../../../network/auth";
import AppConstants from '../../../AppConstants';
import "./subscription.css"

const Subscription = () => {
    const [subscriptions, setSubscription] = useState([])
    const [loading, setLoading] = useState(false);

    let bID = localStorage.getItem(AppConstants.business_id);

    // fetch subscription
  const Subscription = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/subscriptionByBusinessIDList/${bID}`, config)
      .then((response) => {
        setSubscription(response.data.subscriptions)
        setLoading(false);
      })
      .catch((error) => {
        console.error("subscription Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    Subscription();
  }, []);



  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
        field: "order_paid_date",
        headerName: "Order paid date",
        width: 200,
      },
    {
      field: "subscription_amount",
      headerName: "Subscription amount",
      width: 200,
    },
    {
      field: "order_payment_id",
      headerName: "Order payment id",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
    },
    {
      field: "visits_left",
      headerName: "Visits left",
      width: 100,
    },
    {
      field: "subscription_product_name",
      headerName: "Subscription product name",
      width: 200,
    },
    {
      field: "customer_name",
      headerName: "Customer name",
      width: 150,
    },
    
 
   ]
  return (
    <div>
      <Box  
      className="box-container"
       >
          <DataGrid
            pageSize={20}
            autoHeight
            getRowId={(row) => row.id}
            rows={subscriptions}
            columns={columns}
            editMode="row"
          />
      </Box>
    </div>
  )
}
export default Subscription