

import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Popup from "reactjs-popup";
import "./plan_component.css";

const PlanComponent = ({ id, name, onDelete, onEditPlan, onPlanClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editingPlan, setEditingPlan] = useState(false);
  const [editedPlan, setEditedPlan] = useState(name);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editPopupOpen, setEditPopupOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //delete plan
  const handleDelete = () => {
    setDeleteConfirmationOpen(true);
    handleClose();
  };

  const handleConfirmDelete = () => {
    onDelete(id);
    setDeleteConfirmationOpen(false);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationOpen(false);
  };

  //Edit plan
  const handleEdit = () => {
    setEditPopupOpen(true);
    handleClose();
  };

  const handlePlanChange = (event) => {
    setEditedPlan(event.target.value);
  };

  const handleSaveEdit = () => {
    const trimmedName = editedPlan.trim();
    if (trimmedName !== "") {
      onEditPlan(id, trimmedName);
      setEditingPlan(false);
      setEditPopupOpen(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingPlan(false);
    setEditPopupOpen(false);
  };

  const handlePlanClick = () => {
    onPlanClick();
  };

  return (
    <Card>
    <div onClick={handlePlanClick} className="move-to-subplan">
    <Card className="plan-card">
      <Card.Body>
      
       
          <Card.Title className="card-title">{name}</Card.Title>
      
        <Popup
          contentStyle={{ borderRadius: "20px", backgroundColor: "white" }}
          open={editPopupOpen}
          onClose={handleCancelEdit}
          modal
          nested
        >
          {(close) => (
            <div className="edit-div">
              <h3 className="edit-heading-plan">Edit Plan Name</h3>
              <p className="delete-p">Do you want to edit this plan?</p>
              <TextField
                className="edit-input"
                label="New Name"
                type="text"
                value={editedPlan}
                onChange={handlePlanChange}
              />
              <div className="edit-btn-div">
                <Button onClick={handleSaveEdit}>Save</Button>
                <Button
                  onClick={() => {
                    handleCancelEdit();
                    close();
                  }}
                  className="cancel-btn"
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </Popup>
        <Popup
          contentStyle={{ borderRadius: "20px", backgroundColor: "white" }}
          open={deleteConfirmationOpen}
          onClose={handleCancelDelete}
          modal
          nested
        >
          {(close) => (
            <div className="delete-div">
              <h3 className="delete-heading">Are you sure ?</h3>
              <p className="delete-p">Do you want to delete this plan?</p>
              <div className="delete-btn-div">
                <Button onClick={handleConfirmDelete}>Confirm</Button>
                <Button
                  onClick={() => {
                    handleCancelDelete();
                    close();
                  }}
                  className="cancel-btn"
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </Popup>
      </Card.Body>
    </Card>
    </div>
    <IconButton
          className="menu-icon-plan"
          aria-label="more"
          id="long-button"
          aria-controls={anchorEl ? "long-menu" : undefined}
          aria-expanded={anchorEl ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleMenuOpen}
        >
          <span className="material-icons menu-icon">more_vert</span>
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
    </Card>
  );
};

export default PlanComponent;
