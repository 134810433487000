import React, { useState, useEffect } from "react";
import "./faq.css";
import Accordion from "react-bootstrap/Accordion";
import Popup from "reactjs-popup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Container } from "react-bootstrap";
import { baseUrl } from "../../../../network/constants/constants";
import AppConstants from "../../../AppConstants";
import config from "../../../../network/auth";
import { MdEdit, MdDelete } from "react-icons/md";
import axios from "axios";

const Faq = () => {
  const [loading, setLoading] = useState(false);
  const [faq, setFaq] = useState([]);
  const [newFaq, setNewFaq] = useState({
    question: "",
    answer: "",
  });
  const [editFaq, setEditFaq] = useState({
    id: null,
    question: "",
    answer: "",
  });
  const [deleteFaqId, setDeleteFaqId] = useState(null);

  const bID = localStorage.getItem(AppConstants.business_id);

  const handleAddFaqChange = (event) => {
    const { name, value } = event.target;
    setNewFaq({
      ...newFaq,
      [name]: value,
    });
  };

  const handleEditFaqChange = (event) => {
    const { name, value } = event.target;
    setEditFaq({
      ...editFaq,
      [name]: value,
    });
  };

  const handleSubmit = (event, close) => {
    event.preventDefault();
    handleAddFaq(close);
  };

  const handleEditSubmit = (event, close) => {
    event.preventDefault();
    handleEditFaq(editFaq.id, close);
  };

  // Delete FAQ
  const deleteFaq = (id) => {
    setLoading(true);

    axios
      .delete(`${baseUrl}app/faq/edit-or-delete/${id}/`, config)
      .then((response) => {
        setFaq(faq.filter((fa) => fa.id !== id));
        setLoading(false);
      })
      .catch((error) => {
        console.error("deleteFaq Error:", error);
        setLoading(false);
      });
  };

  // Post FAQ
  const handleAddFaq = (close) => {
    setLoading(true);

    const payload = {
      question: newFaq.question,
      answer: newFaq.answer,
      business: bID,
    };

    axios
      .post(`${baseUrl}app/faq/create/`, payload, config)
      .then((response) => {
        fetchFaq();
        setNewFaq({
          question: "",
          answer: "",
        });
        setLoading(false);
        close();
      })
      .catch((error) => {
        console.error("handleAddFaq Error:", error);
        setLoading(false);
      });
  };

  // Patch FAQ
  const handleEditFaq = (id, close) => {
    setLoading(true);

    const payload = {
      question: editFaq.question,
      answer: editFaq.answer,
      business: bID,
    };

    axios
      .patch(`${baseUrl}app/faq/edit-or-delete/${id}/`, payload, config)
      .then((response) => {
        fetchFaq();
        setLoading(false);
        close();
      })
      .catch((error) => {
        console.error("handleEditFaq Error:", error);
        setLoading(false);
      });
  };

  // Get FAQs
  const fetchFaq = () => {
    setLoading(true);

    axios
      .get(`${baseUrl}app/faq/`)
      .then((response) => {
        setLoading(false);
        setFaq(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("fetchFaq Error:", error);
      });
  };

  useEffect(() => {
    fetchFaq();
  }, []);

  return (
    <>
      <section id="faq">
        <div className="faq-section">
          <div className="faq-container">
            <Container>
              <div className="main-heading faq-head">FAQ</div>
              <div className="faq-questions">
                {faq.map((qa) => (
                  <div key={qa.id}>
                    <div className="accordion-div">
                      <Accordion>
                        <Accordion.Item id={qa.id} key={qa.id}>
                          <Accordion.Header>
                            <div className="question">{qa.question}</div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="answer">{qa.answer}</div>
                            <div className="edit-delete-div">
                              <div className="edit">
                                <MdEdit
                                  onClick={() =>
                                    setEditFaq({
                                      id: qa.id,
                                      question: qa.question,
                                      answer: qa.answer,
                                    })
                                  }
                                />
                              </div>
                              <Popup
                                contentStyle={{ borderRadius: "20px" }}
                                trigger={
                                  <div className="delete">
                                    <MdDelete />
                                  </div>
                                }
                                modal
                                nested
                                onOpen={() => setDeleteFaqId(qa.id)}
                              >
                                {(close) => (
                                  <div className="delete-div">
                                    <h3 className="delete-heading">
                                      Are you sure?
                                    </h3>
                                    <p className="delete-p">
                                      Do you want to delete this FAQ?
                                    </p>
                                    <div className="delete-btn-div">
                                      <Button
                                        onClick={() => {
                                          deleteFaq(deleteFaqId);
                                          close();
                                        }}
                                      >
                                        Confirm
                                      </Button>
                                      <Button
                                        onClick={() => close()}
                                        className="cancel-btn"
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                ))}
              </div>

              <Popup
                contentStyle={{ borderRadius: "20px" }}
                trigger={<button className="btn-add">+ Add FAQ</button>}
                modal
                nested
              >
                {(close) => (
                  <div className="add-faq-div">
                    <h5 className="add-faq-heading">Add FAQ</h5>
                    <form onSubmit={(event) => handleSubmit(event, close)}>
                      <div className="faq-textfield-div">
                      <TextField
                        className="add-faq-input"
                        label="Question"
                        type="text"
                        name="question"
                        multiline
                        rows={2}
                        value={newFaq.question}
                        onChange={handleAddFaqChange}
                      />
                      <TextField
                        className="add-faq-input"
                        label="Answer"
                        type="text"
                        name="answer"
                        multiline
                        rows={2}
                        value={newFaq.answer}
                        onChange={handleAddFaqChange}
                      />
                      </div>
                      <div className="delete-btn-div">
                        <Button type="submit">Submit</Button>
                        <Button onClick={close} className="cancel-btn">
                          Cancel
                        </Button>
                      </div>
                    </form>
                  </div>
                )}
              </Popup>

              {editFaq.id && (
                <Popup
                  contentStyle={{ borderRadius: "20px" }}
                  open={!!editFaq.id}
                  closeOnDocumentClick
                  onClose={() =>
                    setEditFaq({ id: null, question: "", answer: "" })
                  }
                >
                  {(close) => (
                    <div className="edit-faq-div">
                      <h5 className="edit-faq-heading">Edit FAQ</h5>
                      <form
                        onSubmit={(event) => handleEditSubmit(event, close)}
                      >
                        <div className="faq-textfield-div">
                        <TextField
                          className="edit-faq-input"
                          label="Question"
                          type="text"
                          name="question"
                          multiline
                          rows={2}
                          value={editFaq.question}
                          onChange={handleEditFaqChange}
                        />
                        <TextField
                          className="edit-faq-input"
                          label="Answer"
                          type="text"
                          name="answer"
                          multiline
                          rows={2}
                          value={editFaq.answer}
                          onChange={handleEditFaqChange}
                        />
                        </div>
                        <div className="delete-btn-div">
                          <Button type="submit">Submit</Button>
                          <Button onClick={close} className="cancel-btn">
                            Cancel
                          </Button>
                        </div>
                      </form>
                    </div>
                  )}
                </Popup>
              )}
            </Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;

















// import React, { useState, useEffect } from "react";
// import "./faq.css";
// import Accordion from "react-bootstrap/Accordion";
// import Popup from "reactjs-popup";
// import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";
// import { Container } from "react-bootstrap";
// import { baseUrl } from "../../../../network/constants/constants";
// import AppConstants from "../../../AppConstants";
// import config from "../../../../network/auth";
// import { MdEdit, MdDelete } from "react-icons/md";
// import axios from "axios";

// const Faq = () => {
//   const [loading, setLoading] = useState(false);
//   const [faq, setFaq] = useState([]);
//   const [newFaq, setNewFaq] = useState({
//     question: "",
//     answer: "",
//   });
//   const [editFaq, setEditFaq] = useState({
//     id: null,
//     question: "",
//     answer: "",
//   });
//   const [editId, setEditId] = useState(null);
//   const [deleteFaqId, setDeleteFaqId] = useState(null);

//   const bID = localStorage.getItem(AppConstants.business_id);

//   const handleAddFaqChange = (event) => {
//     const { name, value } = event.target;
//     setNewFaq({
//       ...newFaq,
//       [name]: value,
//     });
//   };

//   const handleEditFaqChange = (event) => {
//     const { name, value } = event.target;
//     setEditFaq({
//       ...editFaq,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (event, close) => {
//     event.preventDefault();
//     handleAddFaq(close);
//   };

//   const handleEditSubmit = (event, close) => {
//     event.preventDefault();
//     handleEditFaq(editFaq.id, close);
//   };

//   // Delete FAQ
//   const deleteFaq = (id) => {
//     setLoading(true);

//     axios
//       .delete(`${baseUrl}app/faq/edit-or-delete/${id}/`, config)
//       .then((response) => {
//         setFaq(faq.filter((fa) => fa.id !== id));
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("deleteFaq Error:", error);
//         setLoading(false);
//       });
//   };

//   // Post FAQ
//   const handleAddFaq = (close) => {
//     setLoading(true);

//     const payload = {
//       question: newFaq.question,
//       answer: newFaq.answer,
//       business: bID,
//     };

//     axios
//       .post(`${baseUrl}app/faq/create/`, payload, config)
//       .then((response) => {
//         fetchFaq();
//         setNewFaq({
//           question: "",
//           answer: "",
//         });
//         setLoading(false);
//         close();
//       })
//       .catch((error) => {
//         console.error("handleAddFaq Error:", error);
//         setLoading(false);
//       });
//   };

//   // Patch FAQ
//   const handleEditFaq = (id, close) => {
//     setLoading(true);

//     const payload = {
//       question: editFaq.question,
//       answer: editFaq.answer,
//       business: bID,
//     };

//     axios
//       .patch(`${baseUrl}app/faq/edit-or-delete/${id}/`, payload, config)
//       .then((response) => {
//         fetchFaq();
//         setLoading(false);
//         close();
//       })
//       .catch((error) => {
//         console.error("handleEditFaq Error:", error);
//         setLoading(false);
//       });
//   };

//   // Get FAQs
//   const fetchFaq = () => {
//     setLoading(true);

//     axios
//       .get(`${baseUrl}app/faq/`)
//       .then((response) => {
//         setLoading(false);
//         setFaq(response.data);
//       })
//       .catch((error) => {
//         setLoading(false);
//         console.error("fetchFaq Error:", error);
//       });
//   };

//   useEffect(() => {
//     fetchFaq();
//   }, []);

//   return (
//     <>
//       <section id="faq">
//         <div className="faq-section">
//           <div className="faq-container">
//             <Container>
//               <div className="main-heading faq-head">FAQ</div>
//               <div className="faq-questions">
//                 {faq.map((qa) => (
//                   <div key={qa.id}>
//                     <div className="accordion-div">
//                       <Accordion>
//                         <Accordion.Item id={qa.id} key={qa.id}>
//                           <Accordion.Header>
//                             <div className="question">{qa.question}</div>
//                           </Accordion.Header>
//                           <Accordion.Body>
//                             <div className="answer">{qa.answer}</div>
//                             <div className="edit-delete-div">
//                               <div className="edit">
//                                 <MdEdit
//                                   onClick={() =>
//                                     setEditFaq({
//                                       id: qa.id,
//                                       question: qa.question,
//                                       answer: qa.answer,
//                                     })
//                                   }
//                                 />
//                               </div>
//                               <Popup
//                                 contentStyle={{ borderRadius: "20px" }}
//                                 trigger={
//                                   <div className="delete">
//                                     <MdDelete />
//                                   </div>
//                                 }
//                                 modal
//                                 nested
//                                 onOpen={() => setDeleteFaqId(qa.id)}
//                               >
//                                 {(close) => (
//                                   <div className="delete-div">
//                                     <h3 className="delete-heading">
//                                       Are you sure?
//                                     </h3>
//                                     <p className="delete-p">
//                                       Do you want to delete this FAQ?
//                                     </p>
//                                     <div className="delete-btn-div">
//                                       <Button
//                                         onClick={() => {
//                                           deleteFaq(deleteFaqId);
//                                           close();
//                                         }}
//                                       >
//                                         Confirm
//                                       </Button>
//                                       <Button
//                                         onClick={() => close()}
//                                         className="cancel-btn"
//                                       >
//                                         Cancel
//                                       </Button>
//                                     </div>
//                                   </div>
//                                 )}
//                               </Popup>
//                             </div>
//                           </Accordion.Body>
//                         </Accordion.Item>
//                       </Accordion>
//                     </div>
//                   </div>
//                 ))}
//               </div>

//               <Popup
//                 contentStyle={{ borderRadius: "20px" }}
//                 trigger={<button className="btn-add">+ Add FAQ</button>}
//                 modal
//                 nested
//               >
//                 {(close) => (
//                   <div className="add-faq-div">
//                     <h5 className="add-faq-heading">Add FAQ</h5>
//                     <form onSubmit={(event) => handleSubmit(event, close)}>
//                       <div className="faq-textfield-div">
//                       <TextField
//                         className="add-faq-input"
//                         label="Question"
//                         type="text"
//                         name="question"
//                         multiline
//                         rows={2}
//                         value={newFaq.question}
//                         onChange={handleAddFaqChange}
//                       />
//                       <TextField
//                         className="add-faq-input"
//                         label="Answer"
//                         type="text"
//                         name="answer"
//                         multiline
//                         rows={2}
//                         value={newFaq.answer}
//                         onChange={handleAddFaqChange}
//                       />
//                       </div>
//                       <div className="delete-btn-div">
//                         <Button type="submit">Submit</Button>
//                         <Button onClick={close} className="cancel-btn">
//                           Cancel
//                         </Button>
//                       </div>
//                     </form>
//                   </div>
//                 )}
//               </Popup>

//               {editFaq.id && (
//                 <Popup
//                   contentStyle={{ borderRadius: "20px" }}
//                   open={!!editFaq.id}
//                   closeOnDocumentClick
//                   onClose={() =>
//                     setEditFaq({ id: null, question: "", answer: "" })
//                   }
//                 >
//                   {(close) => (
//                     <div className="edit-faq-div">
//                       <h5 className="edit-faq-heading">Edit FAQ</h5>
//                       <form
//                         onSubmit={(event) => handleEditSubmit(event, close)}
//                       >
//                         <div className="faq-textfield-div">
//                         <TextField
//                           className="edit-faq-input"
//                           label="Question"
//                           type="text"
//                           name="question"
//                           multiline
//                           rows={2}
//                           value={editFaq.question}
//                           onChange={handleEditFaqChange}
//                         />
//                         <TextField
//                           className="edit-faq-input"
//                           label="Answer"
//                           type="text"
//                           name="answer"
//                           multiline
//                           rows={2}
//                           value={editFaq.answer}
//                           onChange={handleEditFaqChange}
//                         />
//                         </div>
//                         <div className="delete-btn-div">
//                           <Button type="submit">Submit</Button>
//                           <Button onClick={close} className="cancel-btn">
//                             Cancel
//                           </Button>
//                         </div>
//                       </form>
//                     </div>
//                   )}
//                 </Popup>
//               )}
//             </Container>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Faq;
